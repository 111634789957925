var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_vm._m(0),_c('div',{staticClass:"btn-wrap m-3",staticStyle:{"justify-content":"center","display":"flex","flex-wrap":"wrap"}},[(![3, 4].includes(_vm.statusId))?_c('md-button',{staticClass:"md-raised md-secondary btn-right",staticStyle:{"margin-bottom":"15px !important"},on:{"click":_vm.exportToPDF}},[_vm._v(" PDF indir "),_c('md-icon',{staticClass:"btn-icon"},[_vm._v("download")])],1):_vm._e(),(_vm.session)?_c('md-button',{staticClass:"md-raised md-secondary btn-right",staticStyle:{"margin-bottom":"15px !important"},on:{"click":_vm.goToPatient}},[_vm._v(" Hastaya Dön "),_c('md-icon',{staticClass:"btn-icon"},[_vm._v("person")])],1):_vm._e(),(_vm.session)?_c('md-button',{staticClass:"md-raised md-secondary btn-right",staticStyle:{"margin-bottom":"15px !important"},on:{"click":_vm.goToHomePage}},[_vm._v(" Randevuya Dön "),_c('md-icon',{staticClass:"btn-icon"},[_vm._v("home")])],1):_vm._e()],1),(![3, 4].includes(_vm.statusId))?_c('div',{staticClass:"content-container"},[(_vm.screenWidth < 769)?_c('div',[_vm._m(1),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center","margin-bottom":"15px"}},[_c('div',{style:({
            width: '80%',
            background: _vm.getAppointmentStatusColor(_vm.appoinment.statusId),
            padding: '9px',
            borderRadius: '15px',
            textAlign: 'center',
            'justify-content': 'center',
            'align-items': 'center',
          })},[_c('span',{staticStyle:{"color":"white","font-size":"17px"}},[_vm._v(_vm._s(_vm.appoinment.status ? _vm.appoinment.status.name : ""))])])])]):_vm._e(),(_vm.screenWidth >= 769)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v("Treatment Confirmation Form -")]),_c('div',{style:({
          background: _vm.getAppointmentStatusColor(_vm.appoinment.statusId),
          padding: '9px',
          borderRadius: '15px',
          textAlign: 'center',
          'justify-content': 'center',
          'align-items': 'center',
        })},[_c('span',{staticStyle:{"color":"white","font-size":"17px"}},[_vm._v(_vm._s(_vm.appoinment.status ? _vm.appoinment.status.name : ""))])])]):_vm._e(),(_vm.flightCode && _vm.session)?_c('iframe',{staticClass:"flightIframe",attrs:{"frameborder":"0","scrolling":"yes","src":("https://www.google.com/search?q=" + _vm.flightCode + "&oq=AFR570&igu=1")}}):_vm._e(),(_vm.screenWidth >= 769)?_c('div',{ref:"pdfContentDesktop"},[_c('ConfirmationTemplate',{staticClass:"confirmation-template",attrs:{"notesHide":"","appointmentStatus":_vm.appoinment.status,"confirmation":_vm.content,"template":_vm.template,"avatar":_vm.currentUserAvatar}})],1):_vm._e(),(_vm.screenWidth < 769)?_c('div',{staticStyle:{"height":"1100px","overflow":"hidden"}},[_c('div',{staticStyle:{"transform":"scale(0.45) !important","margin-top":"-75%"}},[_c('ConfirmationTemplate',{staticClass:"confirmation-template",attrs:{"notesHide":"","appointmentStatus":_vm.appoinment.status,"confirmation":_vm.content,"template":_vm.template,"avatar":_vm.currentUserAvatar}})],1)]):_vm._e(),_c('div',{ref:"pdfContent",style:({ display: !_vm.mobileDocumentView ? 'none' : '' })},[_c('ConfirmationTemplate',{staticClass:"confirmation-template",attrs:{"notesHide":"","appointmentStatus":_vm.appoinment.status,"confirmation":_vm.content,"template":_vm.template,"avatar":_vm.currentUserAvatar}})],1)]):_vm._e(),(_vm.statusId == 3)?_c('div',{staticClass:"content-container"},[_c('h3',[_vm._v("Randevu İptal Edilmiştir")])]):_vm._e(),(_vm.statusId == 4)?_c('div',{staticClass:"content-container"},[_c('h3',[_vm._v("Randevu Ertelenmiştir")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"fixed","z-index":"0","height":"100vh","top":"0","-webkit-backface-visibility":"hidden"}},[_c('img',{staticStyle:{"width":"100vw","height":"100vh","object-fit":"cover"},attrs:{"src":"/images/appointment-detail-bg.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center"}},[_c('h3',{staticStyle:{"text-align":"center","width":"80%"}},[_vm._v(" Treatment Confirmation Form ")])])}]

export { render, staticRenderFns }