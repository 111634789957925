import { render, staticRenderFns } from "./languages.vue?vue&type=template&id=38fb502f&scoped=true&"
import script from "./languages.vue?vue&type=script&lang=js&"
export * from "./languages.vue?vue&type=script&lang=js&"
import style0 from "./languages.vue?vue&type=style&index=0&id=38fb502f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fb502f",
  null
  
)

export default component.exports