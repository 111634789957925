import { render, staticRenderFns } from "./hotels.vue?vue&type=template&id=17ccdc60&scoped=true&"
import script from "./hotels.vue?vue&type=script&lang=js&"
export * from "./hotels.vue?vue&type=script&lang=js&"
import style0 from "./hotels.vue?vue&type=style&index=0&id=17ccdc60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ccdc60",
  null
  
)

export default component.exports